
.googleSignInContainer {
  // margin-top: 15vh;
}

.certsContainer {
  display: flex;
  flex-direction: row;

}

.loginContainer {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jogododadoBg {
  background-size: cover;
  resize: both;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bardice100Bg {
  background-image: url("../../../../public/mecca100Bg.png");
  background-size: cover;
  resize: both;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.barDiceLogin {
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: 'Open Sans';
  flex-basis: 100%;
  // width: 90%;
  // max-width: 400px;
  // max-height: 800px;
  // margin: 0 auto;
  padding: 5px;
  // border: red solid 2px;


  .barDiceLoginTitle {
    font-family: "Days One";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #e8c305;
    background-color: transparent;
    font-size: clamp(11px, 3.5vw, 14px);;
  }
  

  .titleContainer {
    margin-top: 15%;
    // width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: white solid 2px;
  }

  .back {
    margin-top: 5%;
    align-self: flex-start;
    font-size: 22px;
    font-weight: 700;

    .visible {
      background: #e8c305;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .invisible {
      @extend .visible;
      background: transparent;
    }
  }

  .bigName {
    font-size: clamp(11px, 8vw, 36px) !important;
    margin-top: 3%;
  }

  .title {
    font-family: 'Asap Condensed';
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 700;
    text-align: center;
    background: #e8c305;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .title-wrapper { margin-bottom: 4%; }

  .phoneEntry {
    // position: absolute;
    // top: 40%;
    width: 100%;
    // margin-bottom: 30%;
    .phoneLine {
      display: flex;
      flex-direction: row;
      flex-basis: 100%;
      .phoneIcon { padding: 3% 4%; }
      input {
        width: 100%;
        font-family: 'Open Sans';
        font-size: 0.9em;
        font-weight: 400;
        color: white;
      }
    }
  }

  .codeEntry {
    // position: absolute;
    // top: 35%;
    // width: 90%;
    .code {
      display: flex;
      justify-content: space-between;
      margin-top: 3%;
      margin-left: 10%;
      margin-right: 10%;
      .gradientBg {
        width: 20%;
      }
      .blackBg {
        width: 100%;
        display: flex;
      }
      input {
        @extend .greyText !optional;
        width: 70%;
        text-align: center;
        font-size: 2.5em;
        &::placeholder {
          font-size: 20px;
          line-height: 150%;
        }
        margin: 0 auto;
        padding-bottom: 10%;
        // padding-top: 20%;
      }
    }
  }

  .greyText {
    color: #EEF1FF;
    font-family: 'Open Sans';
    font-weight: 400;
    text-align: center;
  }

  button {
    min-width: 250px;
    margin: 5% auto;
    padding: 4% 0;

    &.submit {
      color: #161825;
      font-family: 'Asap Condensed';
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      border-radius: 12px;
      background: #e8c305;
      box-shadow: 12px 16px 22px 0px rgba(22, 25, 97, 0.33);
    }

    &.resend {
      @extend .invisibleInput !optional;
      @extend .greyText !optional;
      font-size: 10px;
      text-decoration-line: underline;
    }
  }
}